@import '../../variables.scss';

.error {
  color: $error-color;

  & > div:not(:last-of-type),
  & > input {
    border: 1px solid $error-color;

    &::placeholder {
      color: $error-color;
    }
  }
}

.errorText {
  white-space: pre-line;
  color: $error-color;
  text-align: right;
  font-size: 0.75rem;
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}
