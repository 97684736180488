.group {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  gap: 0 2.5rem;

  @media only screen and (min-device-pixel-ratio: 1.5) {
    gap: 0 0.5rem;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
    gap: 0 0.5rem;
  }

  & > div:first-child {
    flex-grow: 1;
  }

  & > div:last-child {
    flex-basis: 80px;
  }
}
