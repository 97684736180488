@import '../../variables.scss';

.row {
  border-bottom: 1px solid $secondary-bg;
  background-color: #fff;
  padding: 0 6px;

  & > div:not(:last-child) {
    padding: 12px 6px;
  }

  & > div:not(:first-child) {
    text-align: center;
  }
}

.actionWrapper {
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
}

.action {
  cursor: pointer;
  word-break: keep-all;
  font-family: Lora;
}
