.wrapper {
  background-color: #ffffff;
  border: 1px solid #e1e8f2;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.legend {
  display: flex;
  gap: 3rem;
  margin-top: 1.5rem;
}

.legendEntry {
  display: flex;
  gap: 0.5rem;
}

.legendColor {
  height: 18px;
  width: 18px;
  border: 1px solid #707070;
}
