@import '../../variables.scss';

.snackbar {
  min-width: 400px;
  max-width: 560px;
  font-family: 'Lora';
  padding: 1rem 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 50px;
  gap: 30px;
}

.success {
  color: #56ac3a;
  border: 1px solid #56ac3a;
  background-color: #d6ead5;

  path {
    fill: #56ac3a;
  }
}

.error {
  color: #d1192e;
  border: 1px solid #d1192e;
  background-color: #f0cdd3;

  path {
    fill: #d1192e;
  }
}
