@import '../../variables.scss';

.searchLabel {
  margin-bottom: 12px;

  &:after {
    background-color: $green;
    display: block;
    width: 400px;
    height: 1px;
    content: '';
    margin-top: 6px;
  }
}

.controllers {
  display: flex;
  align-items: flex-end;

  & > div:first-child {
    flex-basis: 400px;
  }

  & > * {
    margin-right: 12px;
  }

  & > *:not(:first-child) {
    margin-bottom: 0.75rem;
  }
}
