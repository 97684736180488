@import '../../variables.scss';

.error {
  color: $error-color;

  & > input {
    border: 1px solid $error-color;
  }

  & .fileName {
    color: $error-color;
    border: 1px solid $error-color;
  }
}

.errorText {
  white-space: pre-line;
  color: $error-color;
  text-align: right;
  font-size: 0.75rem;
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}

.container {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
}

.fileName {
  border: 2px solid #e3e2e2;
  border-radius: 3px;
  padding: 12px;
  margin: 0;
  position: relative;

  &__removeBtn {
    position: absolute;
    right: 5px;
    top: 13px;
  }
}

.fileInput {
  visibility: hidden;
  height: 65px;
  margin: 0;
}

.fileInput::file-selector-button {
  visibility: hidden;
}

.innerContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 0% 100%;
}

.fileButton {
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  background-color: white;
  border: 1px solid $green;
  color: $green;
  margin: 0 15px;
  border-radius: 3px;
  padding: 12px;
  outline: none;
  cursor: pointer;
  font-weight: 700;
}
