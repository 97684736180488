@import '../../variables.scss';

.wrapper {
  & > div:not(:last-child) {
    font-weight: bold;
    margin-bottom: 0.2rem;

    span {
      margin-left: 0.3rem;
      color: $green;
    }
  }

  & > div:last-of-type {
    margin: 0.7rem 0;
  }
}
