.cards {
  margin-bottom: 50px;
  display: flex;
  gap: 10px;
  align-items: end;
  padding-top: 80px;
}

.card {
  position: relative;
  flex-grow: 1;
}

.card-content {
  padding: 18px;
  display: grid;
  grid-template-columns: 160px auto;
  grid-gap: 1rem;

  & > div:nth-child(odd) {
    font-family: 'Lora';
  }
}

.card-border {
  border-radius: 5px;
}

.card-top {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  justify-content: end;
  position: absolute;
  bottom: 100%;
  width: 100%;
}

.card-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  padding: 10px;
  height: 60px;
}
