@import '../../variables.scss';

.pagination {
  font-family: Lora;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 1rem;
  background-color: $secondary-bg;
  align-items: baseline;

  @media screen and (min-width: 1300px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.1rem;
  }

  & > div > div {
    padding: 3px 5px;
    margin: 0 2px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: $dark-bg;
      color: #ffffff;
    }
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-last-child(1),
  & > div:nth-last-child(2) {
    margin: 0 12px;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: $dark-bg;
      color: #ffffff;
    }
  }
}

.active {
  background-color: $dark-bg;
  color: #ffffff;
}

.pageNumbers {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}
