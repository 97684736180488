@import '../../variables.scss';

.newMessage {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 560px;
  background-color: #ffffff;
  box-shadow: #00000040 0 3px 6px;

  textarea {
    resize: none;
    border: none;
    padding: 1rem;
  }
}

.messageHeader {
  font-size: 1.25rem;
  padding: 0.75rem 1.25rem;
  background-color: $green;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #ffffff;
  }

  div {
    cursor: pointer;
  }

  svg path {
    stroke: #ffffff;
  }
}

.newMessageButtonRow {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-top: 1px solid #e1e8f2;
}
