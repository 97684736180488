.label {
  display: flex;
  gap: 8px;
  align-items: center;
}

.selectedColor {
  width: 16px;
  height: 16px;
  border: 2px solid #707070;
}

.options {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(3, 35px);
  margin-bottom: 0.75rem;
  border: 2px solid #707070;
}
