@import '../../variables.scss';

.sidebar {
  height: 100%;
  width: 300px;
  background-color: $secondary-bg;
  padding: 1.5rem;
}

.scale {
  width: 150px;
  padding: 1.5em;
  font-size: 9px;
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 1em;
}

.menu {
  font-size: 1.2em;

  & > a {
    padding: 1.25em 0;
    display: flex;
    gap: 10px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    align-items: center;

    &:hover {
      color: $green;
    }

    &:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      background-color: #d5dce6;
      bottom: 0;
    }
  }
}

.menuActive {
  color: $green;

  svg path {
    fill: $green;
  }
}

.primaryNavItem {
  background-color: $green;
  color: #ffffff;
  border: 1px solid transparent;
  margin: 1em -0.5em;
  border-radius: 4px;
  padding: 0.75em !important;

  &::after {
    top: 130%;
    left: 0.5em !important;
    right: 0.5em !important;
  }

  &:hover {
    color: #ffffff !important;
  }

  svg path {
    fill: #ffffff;
  }
}
