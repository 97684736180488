.wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;

  & > div {
    max-width: 190px;
    flex-basis: 90px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
