.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  flex-grow: 1;

  & > div:nth-child(2) {
    flex-grow: 1;
  }
}

.notification-wrapper {
  position: fixed;
  margin: auto;
  width: fit-content;
  top: 60px;
  left: 40px;
  right: 40px;
  z-index: 9999999;
}

.loader-wrapper {
  position: fixed;
  width: 1rem;
  height: 4rem;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  color: #fff;
  position: relative;
  font-size: 11px;
  background: #fff;
  animation: escaleY 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
  left: 2em;
  background: #fff;
  width: 1em;
  height: 4em;
  animation: escaleY 1s infinite ease-in-out;
}
.loader:before {
  left: -2em;
  animation-delay: -0.32s;
}

@keyframes escaleY {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
