@import '../../variables.scss';

.wrapper {
  box-shadow: 0px 0px 5px #d3d0d0;
  word-break: break-word;
  border: 1px solid $secondary-bg;
  border-radius: 5px;
  font-size: 0.8rem;

  @media screen and (min-width: 1300px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.1rem;
  }
}

.footerValues {
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
  background-color: $secondary-bg;

  & > div {
    padding: 0.75rem;
  }
}
