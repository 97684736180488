.status {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;

  img {
    height: 1.4em;
    padding: 0.3em;
    cursor: pointer;
  }
}
