@import '../../variables.scss';

.checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1rem;
}

.opener {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.warning {
  font-family: 'Lora';
  padding: 1rem 1.5rem;
  border-radius: 5px;
  color: $error-color;
  border: 1px solid $error-color;
  background-color: #f0cdd3;

  a {
    text-decoration-line: underline;
  }
}
